<template>
  <div class="home" :style="{ height: clientHeight - 0 + 'px' }">
    <div ref="clink" style="display: none"></div>
    <el-container>
      <!-- 左侧 -->
      <el-aside
        width="auto"
        :style="{ height: clientHeight - 0 + 'px' }"
        style="
          overflow-y: scroll;
          scrollbar-width: none;
          -webkit-overflow-scrolling: touch;
        "
      >
        <el-radio-group
          v-model="isCollapse"
          style="display: block; height: 70px"
        >
          <div
            style="
              position: fixed;
              z-index: 999;
              background: #d78673;
              width: 260px;
            "
            v-show="!isCollapse"
            @click="isCollapse = true"
          >
            <img class="careldimg" src="../assets/images/logo.png" alt />
          </div>
          <div
            style="position: fixed; z-index: 999; background: #d78673"
            v-show="isCollapse"
            @click="isCollapse = false"
          >
            <img class="careldimgs" src="../assets/fhlogo.png" alt />
          </div>
        </el-radio-group>
        <div>
          <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            :collapse="isCollapse"
            :unique-opened="true"
            router
          >
            <!-- 左侧循环 -->
            <template v-for="(vo, index) in leftList">
              <!-- 二级菜单 -->
              <el-submenu
                :index="index + ''"
                :key="index"
                @click.native="handleOpen()"
                v-if="vo.children && vo.children.length != 0"
                class="el-menu-items"
              >
                <!-- 一级 -->
                <template slot="title">
                  <img width="30px" height="30px" :src="vo.meta.url" alt />
                  <span class="spanleft">{{ vo.meta.title }}</span>
                </template>
                <!-- 一级里的二级 -->
                <el-menu-item-group>
                  <el-menu-item
                    v-for="item in vo.children"
                    :key="item.id"
                    :index="item.path"
                    >{{ item.meta.title }}</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <!-- 只有一级 -->
              <el-menu-item
                class="el-menu-items menu-items"
                :index="vo.path"
                :key="index"
                v-else
              >
                <img width="30px" height="30px" :src="vo.meta.url" alt />
                <span class="spanleft" slot="title">{{ vo.meta.title }}</span>
              </el-menu-item>
            </template>

            <div class="teml">
              <template v-for="(vos, indexs) in jurisdiction">
                <!-- 二级菜单 -->
                <el-submenu
                  :index="indexs + ''"
                  :key="indexs"
                  v-if="vos.parentId == 0"
                >
                  <!-- 一级 -->
                  <template slot="title">
                    <span class="spanleft">{{ vos.menuName }}</span>
                  </template>
                  <!-- 一级里的二级 -->
                  <el-menu-item-group>
                    <div
                      v-for="(item, index) in jurisdiction.children"
                      :key="index"
                    >
                      <el-menu-item
                        :index="item.url"
                        v-if="item.parentId != 0"
                        >{{ item.menuName }}</el-menu-item
                      >
                    </div>
                  </el-menu-item-group>
                </el-submenu>
              </template>
            </div>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <!-- 头部 -->
        <el-header>
          <div class="block">
            <iframe
              :src="wifiUrl"
              scrolling="auto"
              frameborder="0"
              style="width: auto; height: 60px"
            ></iframe>
            <img
              :src="this.imageUrl == null ? this.mg : this.imageUrl"
              alt=""
              id="headImage"
            />
            <!-- <span class="span2" @click="dialogFormVisible = true">个人中心</span> -->
            <el-popover placement="top-start" trigger="hover">
              <img
                :src="this.imageUrl == null ? this.mg : this.imageUrl"
                style="
                  width: 60px;
                  height: 60px;
                  margin: 6px 50px;
                  border-radius: 50%;
                  border: 2pxsolid #d78673;
                "
                alt=""
                id="headImage1"
              />
              <span
                style="
                  text-align: center;
                  display: block;
                  font-size: 15px;
                  margin: 15px 0;
                "
                >{{ fullName }}</span
              >
              <span
                style="
                  text-align: center;
                  display: block;
                  font-size: 15px;
                  margin: 15px 0;
                "
                >{{ orgName }}</span
              >
              <span
                style="
                  text-align: center;
                  display: block;
                  font-size: 15px;
                  margin: 15px 0;
                "
                >{{ roleNmae }}</span
              >
              <span class="popospan" style="cursor: pointer" @click="changePwd"
                >修改密码</span
              >
              <span
                class="popospan"
                style="cursor: pointer"
                @click="dialogFormVisiblephone = true"
                >换绑手机</span
              >
              <span
                class="popospan"
                style="cursor: pointer"
                @click="changepicture"
                >更换头像</span
              >
              <span class="popospan" style="cursor: pointer" @click="loginOut"
                >退出</span
              >
              <el-button class="span3" slot="reference">个人中心</el-button>
            </el-popover>
          </div>
          <!-- 修改密码 -->
          <el-dialog title="修改密码" :visible.sync="dialogFormVisiblepwd">
            <div class="main">
              <el-form class="pwdform" :model="form" label-position="top">
                <el-form-item
                  label="请输入新密码"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    type="text"
                    v-model="form.pwd"
                    placeholder="请输入新密码"
                    autocomplete="off"
                    style="width: 85%; margin-right: 10px"
                    v-if="showPwd"
                  ></el-input>
                  <el-input
                    type="password"
                    v-model="form.pwd"
                    placeholder="请输入新密码"
                    autocomplete="off"
                    style="width: 85%; margin-right: 10px"
                    v-else
                  ></el-input>
                  <img
                    @click="reveal"
                    src="https://s4.ax1x.com/2022/01/07/79E7dg.png"
                    v-if="codeType == 1"
                  />
                  <img
                    @click="conceal"
                    src="https://s4.ax1x.com/2022/01/07/79EOWn.png"
                    v-if="codeType == 0"
                  />
                </el-form-item>
                <el-form-item
                  label="请重复一边输入的密码"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    type="text"
                    v-model="form.password"
                    placeholder="重复输入新密码"
                    autocomplete="off"
                    style="width: 85%; margin-right: 10px"
                    v-if="showPwd1"
                  ></el-input>
                  <el-input
                    type="password"
                    v-model="form.password"
                    placeholder="重复输入新密码"
                    autocomplete="off"
                    style="width: 85%; margin-right: 10px"
                    v-else
                  ></el-input>
                  <img
                    @click="reveal1"
                    src="https://s4.ax1x.com/2022/01/07/79E7dg.png"
                    v-if="codeType1 == 1"
                  />
                  <img
                    @click="conceal1"
                    src="https://s4.ax1x.com/2022/01/07/79EOWn.png"
                    v-if="codeType1 == 0"
                  />
                </el-form-item>
                <el-form-item label="验证码" :label-width="formLabelWidth">
                  <el-input
                    class="yzm"
                    v-model="form.code"
                    autocomplete="off"
                  ></el-input>
                  <el-button class="yzmbut" @click="obtain">获取</el-button>
                </el-form-item>
              </el-form>
              <div slot="footer" class="but">
                <el-button type="primary" @click="determine">确定</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
              </div>
            </div>
          </el-dialog>
          <!-- 换绑手机 -->
          <el-dialog title="换绑手机" :visible.sync="dialogFormVisiblephone">
            <div class="main">
              <el-form class="pwdform" :model="changetel" label-position="top">
                <el-form-item
                  label="请输入新手机号"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="changetel.phone"
                    placeholder="请输入新手机号"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="验证码" :label-width="formLabelWidth">
                  <el-input
                    class="yzm"
                    v-model="changetel.code"
                    autocomplete="off"
                  ></el-input>
                  <el-button class="yzmbut" @click="obtaintel">{{
                    content
                  }}</el-button>
                </el-form-item>
              </el-form>
              <div slot="footer" class="but">
                <el-button type="primary" @click="tiechange">确定</el-button>
                <el-button type="primary" @click="cancelchange">取消</el-button>
              </div>
            </div>
          </el-dialog>
          <!-- 上传头像弹框 -->
          <el-dialog title="上传头像" :visible.sync="dialogFormVisible">
            <input
              type="file"
              ref="file"
              id="tuPian"
              accept="image/*"
              @change="flieChange"
              style="margin-left: 20px"
            />
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancelPopout">取 消</el-button>
              <el-button type="primary" @click="uploadHeader"
                >确定上传</el-button
              >
            </div>
          </el-dialog>
          <!-- 退出登陆 -->
          <el-dialog title="退出" :visible.sync="dialogFormVisiblequit">
            <div class="main">
              <div class="quit">确定退出登录</div>
              <div slot="footer" class="but">
                <el-button type="primary" @click="quitlogin">确定</el-button>
                <el-button type="primary" @click="changequit">取消</el-button>
              </div>
            </div>
          </el-dialog>
        </el-header>
        <el-main>
          <!-- 面包屑 -->
          <div class="crumbs" v-if="isBread">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item
                v-for="(item, index) in breadList"
                :key="index"
                :to="{ path: item.path }"
                >{{ item.meta.title }}</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>
          <div v-if="isBreads"></div>
          <!-- <div v-if="isBreads" style="height: 41px"></div> -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import * as qiniu from "qiniu-js";
//import store from '../store';
import { api } from "../api1/config";
import { mapMutations } from "vuex";
import image from "../assets/27.jpg";
import { apiShout } from "../api1/configShout";
import { base } from "../api1/config";
export default {
  inject: ["reload"],
  data() {
    return {
      codeType: 0,
      codeType1: 0,
      showPwd: false,
      showPwd1: false,
      clientHeight: document.body.clientHeight,
      wifiUrl: "",
      dialogVisibleLine: false,
      mask: false,
      excellent: false,
      excellentimg: "",
      good: false,
      goodimg: "",
      difference: false,
      differenceimg: "",
      noNet: false,
      noNetimg: "",
      delay: "",
      count: "",
      timerData: null,
      counts: 1,
      isBread: true,
      isBreads: false,
      options: {
        showHold: false,
        showConsult: true,
        showTransfer: true,
        showSatisfaction: true,
        showMute: true,
      },
      mg: image,
      model: {
        icon: "../assets/images/avatar.jpg",
      },
      imageUrl: "",
      fullName: "",
      orgName: "",
      roleNmae: "",
      // 更改头像弹框
      dialogFormVisible: false,
      // 修改密码弹框
      dialogFormVisiblepwd: false,
      // 换绑手机
      dialogFormVisiblephone: false,
      changetel: {
        phone: "",
        code: "",
      },
      verificationcode: {
        phone: "",
      },
      // 退出
      dialogFormVisiblequit: false,
      // 获取验证码
      content: "发送验证码",
      totalTime: 60,
      canClick: true, //添加canClick
      openeds: ["1"],
      isCollapse: false,
      // 取session
      userinfo: {},
      form: {
        pwd: "",
        password: "",
        code: "",
        // phone: "",
      },
      formLabelWidth: "120px",
      leftList: [],
      jurisdiction: {},
      token: "", //上传头像的token
      accessKey: "",
      bucket: "",
      secretKey: "",
      hash: "",
      key: "",
      id: "", //用户id
      prefix: "",
      houtai: "",
      dataLists: {},
      serviceId: "",
      cusSerSign: "", //是否是客服
      curSerNum: "",
    };
  },
  watch: {
    //路由改变的时候监听
    $route() {
      this.getBreadcrumb();
    },
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  //  computed: {
  //   ...mapState("routeModule", ["menuList"]),
  // },

  created() {
    //获取是否为客服
    let curSer = JSON.parse(localStorage.getItem("cusSer"));
    this.curSerNum = curSer;
    window["vueDefinedMyProp"] = (receiveParams) => {
      this.Disconnection(receiveParams);
    };
    this.getBreadcrumb();
    //这里是获取到你session数据转换对象因为是字符串
    this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
    console.log(this.userinfo, "userinfo");
    if (this.userinfo == null) {
      this.$router.push("/");
      console.log(this.userinfo, "userinfo退出");
    }
    this.form.name = this.userinfo.userName;
    this.form.phone = this.userinfo.phone;
    this.id = this.userinfo.id;

    this.houtai = JSON.parse(window.localStorage.getItem("houtai"));
    this.leftList = this.houtai;
    //this.leftList = store.state.routeModule.menuList;
    //console.log('------',store.state.routeModule.menuList);
    //图片获取
    let imgUrl = JSON.parse(sessionStorage.getItem("photoUrl"));
    console.log("====", imgUrl);
    this.imageUrl = imgUrl;
    let names = JSON.parse(sessionStorage.getItem("name"));
    this.fullName = names;
    let orgNames = JSON.parse(sessionStorage.getItem("orgName"));
    this.orgName = orgNames;
    let roles = JSON.parse(sessionStorage.getItem("role"));
    this.roleNmae = roles;
  },
  mounted() {
    let domContainer = this.$refs.clink;
    new ClinkToolbar(this.options, domContainer, this.callback);
    console.log(ClinkToolbar);
    this.showSessionId = window.location.search;
    this.wifiUrl = `/static/wifi.html${this.showSessionId}`;
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  methods: {
    handleOpen() {
      let loginUserId = JSON.parse(localStorage.getItem("token"));
      if (loginUserId == null || loginUserId == "") {
        this.$message.error("token已过期，请重新登录!");
        this.$router.push("/");
      }
    },
    changePwd() {
      this.dialogFormVisiblepwd = true;
      this.codeType = 0;
      this.codeType1 = 0;
      this.showPwd = false;
      this.showPwd1 = false;
    },
    //显示事件
    reveal() {
      this.codeType = 0;
      this.showPwd = false;
    },
    //隐藏事件
    conceal() {
      this.codeType = 1;
      this.showPwd = true;
    },
    //显示事件
    reveal1() {
      this.codeType1 = 0;
      this.showPwd1 = false;
    },
    //隐藏事件
    conceal1() {
      this.codeType1 = 1;
      this.showPwd1 = true;
    },
    ...mapMutations("MatchedsModule", ["setMatch"]),
    callback() {},
    //判断断网退出
    Disconnection(data) {
      if (data === 1) {
        this.$router.push("/");
        this.quitlogin();
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.logoutMode = 1;
          params.removeBinding = 0;
          ClinkAgent.logout(params);
        });
      }
    },
    //更换头像
    changepicture() {
      this.dialogFormVisible = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "photo" }).then((res) => {
        //console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //上传头像
    uploadHeader() {
      this.dialogFormVisible = false;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //获取本地
      let bIms = JSON.parse(localStorage.getItem("backImages"));
      // console.log(this.$refs.file.files);
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          //console.log("000", res);
          console.log(res.hash, res.key);
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          sessionStorage.setItem("photoUrl", JSON.stringify(this.imageUrl));
          document.getElementById("headImage").src = bIms;
          document.getElementById("headImage1").src = bIms;
          loading.close();
          // alert('上传成功');
          // this.key = res.key;
          sessionStorage.setItem("hash", JSON.stringify(res.key));
          document.getElementById("tuPian").value = "";
          // localStorage.setItem("hash", JSON.stringify(res.key));
        },
        //上传错误时
        error() {
          let file = JSON.parse(localStorage.getItem("file"));
          //console.log('上传失败',err.stack);
          let key = JSON.parse(localStorage.getItem("hash"));
          let that = this;
          that.token = JSON.parse(sessionStorage.getItem("token"));
          //console.log("token", that.token);
          that
            .$http({
              url: base.errorQiniu,
              method: "POST",
              params: { uploadFile: file, key: key },
              headers: {
                token: that.token,
              },
            })
            .then((dd) => {
              console.log("上传失败：", dd.data);
            });
        },
      };
      observable.subscribe(observer); // 上传开始
      this.userHeader(this.prefix + this.id + shijiancuo + ming);
      //  //修改路径
      // let hash = JSON.parse(sessionStorage.getItem("hash"));
      // this.userHeader(hash);
    },
    //封装的修改用户头像的接口
    userHeader(key) {
      //1.7.修改用户头像路径接口
      api.changeUserPhoto({ fileName: key }).then((data) => {
        console.log("ppp", data.data);
        if (data.data.code === 200) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          // this.imageUrl = data.data.result;
          //将路径存储本地
          // sessionStorage.setItem("lujin", JSON.stringify(data.data.result));
        }
      });
    },
    //上传头像格式限制
    flieChange(e) {
      // console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      var file = e.target.files[0];
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //  document.getElementById("gengaiImage").src=e.target.result
          //保存本地
          localStorage.setItem("backImages", JSON.stringify(e.target.result));
          //  this.baseImg=e.target.result;
        };
      }
      //大小限制
      var filemaxsize = 1024; //1M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    // 绑换手机获取验证码
    obtaintel() {
      var that = this;
      that
        .$http({
          url: "/too/login/sendPhoneCode",
          params: { phone: that.changetel.phone },
          method: "POST",
        })
        .then((res) => {
          //验证数据是否获取到
          if (res.data.code == 200) {
            that.$message({
              message: "验证码已发送",
              type: "success",
            });
            if (!that.canClick) return; //改动的是这两行代码
            that.canClick = false;
            that.content = that.totalTime + "s后重新发送";
            let clock = window.setInterval(() => {
              that.totalTime--;
              that.content = that.totalTime + "s后重新发送";
              if (that.totalTime < 0) {
                window.clearInterval(clock);
                that.content = "重新发送验证码";
                that.totalTime = 60;
                that.canClick = true; //这里重新开启
              }
            }, 1000);
          } else {
            that.$message.error("验证码发送失败");
          }
        });
    },
    // 绑换手机
    tiechange() {
      let that = this;
      if (that.changetel.phone == "") {
        that.$message.error("请输入新手机号");
      } else if (!/^1[0123456789]\d{9}$/.test(that.changetel.phone)) {
        that.$message.error("手机号格式不正确");
      } else if (that.changetel.code == "") {
        that.$message.error("请输入验证码");
      } else {
        that
          .$http({
            url: "/too/user/updatePhone",
            params: that.changetel,
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              token: that.userinfo.token,
            },
          })
          .then((res) => {
            console.log("换绑手机号", res);
            if (res.data.msg == "请输入正确的验证码") {
              that.$message.error("验证码不正确");
            } else if (res.data.code == 200) {
              that.$message({
                message: "换绑手机成功",
                type: "success",
              });
              that.content = "发送验证码";
              that.dialogFormVisiblephone = false;
              //清空输入的数据
              this.changetel = {};
            }
          });
      }
    },
    isHome(route) {
      //拿到首页
      return route.name === "name";
    },
    getBreadcrumb() {
      let matched = this.$route.matched; //拿到显示的路由路径
      console.log(this.$route.matched, "route.matched");
      // console.log("matched", matched);
      //存储到vuex中
      // this.setMatch(matched[1].meta.title);
      // console.log(matched[1].meta.title);

      if (!this.isHome(matched[0])) {
        //当前路由等于首页
        matched = [
          { path: "/system_query/central", meta: { title: "首页" } },
        ].concat(matched);
      }
      this.breadList = matched;
      console.log(matched, "matched");
      if (this.curSerNum == "1") {
        this.isBread = false;
        this.isBreads = true;
      }
    },
    //取消的方法
    cancelPopout() {
      this.dialogFormVisible = false;
      document.getElementById("tuPian").value = "";
    },
    // 取消帮换手机
    cancelchange() {
      this.dialogFormVisiblephone = false;
      this.changetel = {};
    },
    // 取消修改密码
    cancel() {
      this.dialogFormVisiblepwd = false;
      this.form = {};
    },
    loginOut() {
      // this.dialogFormVisiblequit = true;
      //获取本地存储
      // let phs = JSON.parse(localStorage.getItem("phone"));
      let ph = JSON.parse(localStorage.getItem("phoneNumber"));
      console.log("ph===", ph);
      // console.log("phs111",phs)
      let loginUserId = JSON.parse(localStorage.getItem("loginuser"));
      this.serviceId = loginUserId.userName;
      this.cusSerSign = loginUserId.cusSerSign;
      if (this.cusSerSign !== "1") {
        localStorage.removeItem("phone");
        localStorage.removeItem("phoneNumber");
      }
      // console.log('login========:',loginUserId);
      // console.log('phone:',ph,this.serviceId);
      // console.log('=========this.serviceId:',this.serviceId);
      if (ph == null) {
        this.dialogFormVisiblequit = true;
      } else {
        this.$message({
          message: "不好意思，正在通话中，不能退出登录",
          type: "success",
        });
        this.dialogFormVisiblequit = false;
      }
    },
    // 退出登录
    quitlogin() {
      if (this.cusSerSign == "1") {
        //天润的退出
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.logoutMode = 1;
          params.removeBinding = 0;
          ClinkAgent.logout(params);
        });
        //离线
        //获取cno
        let cno = JSON.parse(localStorage.getItem("cno"));
        let token = JSON.parse(localStorage.getItem("token"));
        apiShout.getServerOffLine({ cno: cno, token: token }).then((res) => {
          if (res.data.code == 200) {
            let that = this;
            that
              .$http({
                url: "/too/login/logOut",
                params: { name: that.userinfo.userName },
                method: "POST",
              })
              .then((res) => {
                console.log("退出：", res.data);
                if (res.data.code == 200) {
                  that.$message({
                    message: "成功退出登录",
                    type: "success",
                  });
                  //清除缓存
                  window.localStorage.removeItem("houtai");
                  window.localStorage.removeItem("loginuser");
                  window.localStorage.removeItem("hash");
                  window.localStorage.removeItem("file");
                  window.localStorage.removeItem("loglevel");
                  window.localStorage.removeItem("token");
                  window.localStorage.removeItem("isNew");
                  window.localStorage.removeItem("createOrder");
                  window.localStorage.removeItem("cusSer");
                  window.localStorage.removeItem("memberId");
                  window.localStorage.removeItem("serverStatus");
                  window.localStorage.removeItem("listionPhone");
                  window.localStorage.removeItem("serPhone");
                  window.localStorage.removeItem("serEquId");
                  window.localStorage.removeItem("serUserId");
                  window.localStorage.removeItem("yuanInfor");
                  window.localStorage.removeItem("strequipmentIds");
                  window.sessionStorage.clear();
                  that.$router.replace("/");
                  clearInterval(this.timer);
                  clearInterval(this.timerData);
                  clearInterval(this.timer1);
                  clearInterval(this.timer2);
                  clearInterval(this.timer3);
                  clearInterval(this.timer4);
                  clearInterval(this.timer5);
                  clearInterval(this.timer6);
                  clearInterval(this.timer8);
                } else {
                  that
                    .$http({
                      url: "/too/center/weblog/save",
                      params: {
                        callback: "",
                        errorCode: res.data.code,
                        errorExplain: res.data.msg,
                        interfaceUrl: res.config.url,
                        menuName: "客服系统-退出登录",
                        menuUrl: this.$route.path,
                        params: res.config.params,
                        source: "客服系统",
                        userId: this.userinfo.id,
                        userName: this.userinfo.name,
                      },
                      headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                      },
                      method: "get",
                    })
                    .then((res) => {
                      if (res.data.code === 200) {
                        console.log();
                      }
                    });
                  this.$message.error("退出登陆失败，请与系统管理员联系!");
                  this.dialogFormVisiblequit = false;
                  return false;
                }
              });
          } else {
            this.$message.error("退出登陆失败，请与系统管理员联系!");
            this.dialogFormVisiblequit = false;
            return false;
          }
        });
        clearInterval(this.timer);
        clearInterval(this.timerData);
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer3);
        clearInterval(this.timer4);
        clearInterval(this.timer5);
        clearInterval(this.timer6);
        clearInterval(this.timer8);
      } else {
        let that = this;
        that
          .$http({
            url: "/too/login/logOut",
            params: { name: that.userinfo.userName },
            method: "POST",
          })
          .then((res) => {
            console.log("退出：", res.data);
            if (res.data.code == 200) {
              that.$message({
                message: "成功退出登录",
                type: "success",
              });
              //清除缓存
              window.localStorage.removeItem("houtai");
              window.localStorage.removeItem("loginuser");
              window.localStorage.removeItem("hash");
              window.localStorage.removeItem("file");
              window.localStorage.removeItem("loglevel");
              window.localStorage.removeItem("token");
              window.localStorage.removeItem("isNew");
              window.localStorage.removeItem("createOrder");
              window.localStorage.removeItem("cusSer");
              window.localStorage.removeItem("memberId");
              window.localStorage.removeItem("callogValue");
              window.localStorage.removeItem("serverStatus");
              window.localStorage.removeItem("listionPhone");
              window.localStorage.removeItem("serPhone");
              window.localStorage.removeItem("serEquId");
              window.localStorage.removeItem("serUserId");
              window.localStorage.removeItem("yuanInfor");
              window.localStorage.removeItem("strequipmentIds");
              window.sessionStorage.clear();
              that.$router.replace("/");
              clearInterval(this.timer);
              clearInterval(this.timerData);
              clearInterval(this.timer1);
              clearInterval(this.timer2);
              clearInterval(this.timer3);
              clearInterval(this.timer4);
              clearInterval(this.timer5);
              clearInterval(this.timer6);
              clearInterval(this.timer8);
            } else {
              that
                .$http({
                  url: "/too/center/weblog/save",
                  params: {
                    callback: "",
                    errorCode: res.data.code,
                    errorExplain: res.data.msg,
                    interfaceUrl: res.config.url,
                    menuName: "客服系统-退出登录",
                    menuUrl: this.$route.path,
                    params: res.config.params,
                    source: "客服系统",
                    userId: this.userinfo.id,
                    userName: this.userinfo.name,
                  },
                  headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                  },
                  method: "get",
                })
                .then((res) => {
                  if (res.data.code === 200) {
                    console.log();
                  }
                });
              this.$message.error("退出登陆失败，请与系统管理员联系!");
              this.$router.push("/");
              this.dialogFormVisiblequit = false;
              return false;
            }
          });
      }
      // console.log('=========',userCno);
    },
    // 取消退出
    changequit() {
      this.dialogFormVisiblequit = false;
    },
    // 获取手机号验证码
    obtain() {
      var that = this;
      // 找回密码
      that
        .$http({
          url: "/too/login/sendPhoneCode",
          params: { phone: that.userinfo.phone },
          method: "POST",
        })
        .then((res) => {
          //验证数据是否获取到
          console.log(res);
          if (res.data.code == 200) {
            this.$message({
              message: "验证码已发送",
              type: "success",
            });
          } else {
            this.$message.error("验证码发送失败");
          }
        });
    },
    // 确定修改
    determine() {
      var that = this;
      //  修改密码
      that
        .$http({
          url: "/too/login/updatePassWord",
          params: that.form,
          method: "POST",
        })
        .then((res) => {
          console.log("确定修改密码：", res.data);
          if (res.data.code == 200) {
            this.$message({
              message: "密码修改成功",
              type: "success",
            });
            this.$router.replace("/");
          } else {
            this.$message.error("请输入正确的验证码");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    close() {
      this.global.ws.close(); //关闭websocket
      this.global.ws.onclose = function (e) {
        console.log(e); //监听关闭事件
        console.log("关闭");
        //退出天润的登录
        ClinkAgent.setup({ sipPhone: true, debug: false }, function () {
          // 传入参数对象
          var params = {};
          params.logoutMode = 1;
          params.removeBinding = 0;
          ClinkAgent.logout(params);
        });
        //清除缓存
        window.localStorage.removeItem("houtai");
        window.localStorage.removeItem("loginuser");
        window.localStorage.removeItem("hash");
        window.localStorage.removeItem("file");
        window.localStorage.removeItem("loglevel");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("isNew");
        window.localStorage.removeItem("createOrder");
        window.localStorage.removeItem("cusSer");
        window.localStorage.removeItem("memberId");
        window.localStorage.removeItem("serverStatus");
        window.localStorage.removeItem("listionPhone");
        window.localStorage.removeItem("serPhone");
        window.localStorage.removeItem("serEquId");
        window.localStorage.removeItem("serUserId");
        window.localStorage.removeItem("yuanInfor");
        window.localStorage.removeItem("strequipmentIds");
        window.sessionStorage.clear();
        // this.$router.replace("/");
        clearInterval(this.timer);
        clearInterval(this.timerData);
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer3);
        clearInterval(this.timer4);
        clearInterval(this.timer5);
        clearInterval(this.timer6);
        clearInterval(this.timer8);
      };
      this.$router.push("/");
    },
  },
  beforeDestroy() {
    this.close();
    clearInterval(this.timer);
    clearInterval(this.timerData);
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
    clearInterval(this.timer5);
    clearInterval(this.timer6);
    clearInterval(this.timer8);
  },
};
</script>
<style lang="less">
.el-menu--vertical {
  position: absolute !important;
  max-height: 380px !important;
  overflow-y: scroll !important;
}
.offline-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  z-index: 999;
  transition: position 2s;
  display: flex;
  align-items: center;
  justify-content: center;
  .offline-mask-title {
    color: rgba(0, 0, 0, 0.8);
  }
}
.home {
  // 头部
  .el-header {
    display: flex;
    background: #ffffff;
    justify-content: flex-end;
    .block {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        display: block;
        margin: 0 auto;
        border-radius: 50%;
        border: 2px solid #d78673;
        margin-right: 0px;
        margin-left: 20px;
      }
      .span3 {
        background: transparent;
        border: none;
        color: #d78673;
        font-size: 17px;
        cursor: pointer;
        margin-right: 24px;
      }
    }
  }
  // 左侧
  .el-menu {
    border-right-width: 0;
  }
  .el-aside {
    background: #d78673;
    color: #333;
    border-right: none;
    box-sizing: border-box;
    .el-submenu__title i {
      color: transparent;
    }
    .el-submenu__icon-arrow {
      background: url("../assets/images/down.png") no-repeat 0 3px;
      right: 0px;
      position: initial;
      top: 0px;
      margin-left: 30px;
      margin-top: 0px;
      padding-right: 25px;
    }
    // 图标logo
    .careldimg {
      margin: 20px;
    }
    .careldimgs {
      margin: 16px;
      width: 32px;
    }
    // 一级
    .spanleft {
      font-size: 21px;
      color: #ffffff;
      margin-left: 20px;
    }
    // 选中后改变颜色
    .el-menu-item.is-active {
      background: #f8d0c6 !important;
    }
    // 有二级的一级
    .el-submenu__title {
      background: #d78673;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      padding-left: 39px !important;
    }
    // 二级
    .el-menu-item-group__title {
      padding: 0;
    }
    // 只有一级
    .el-menu-items {
      background: #d78673;
    }
    // 二级内边距
    .el-menu-item {
      padding: 0 !important;
    }
    .menu-items {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
    }
    .el-menu-item-group {
      padding-top: 30px;
      padding-bottom: 30px;
      background: #f1a795;
    }
    // 二级子类
    .el-submenu .el-menu-item {
      height: 53px;
      line-height: 53px;
      padding: 0;
      font-size: 20px;
      background: #f1a795;
      min-width: 0;
      text-align: center;
      color: #ab6a5b;
    }
  }
  // 主题
  .el-main {
    background-color: #ffffff;
    color: #333;
    padding: 0;
    position: relative;
    height: 840px;
    // 面包屑
    .crumbs {
      margin-left: 25px;
      margin-top: 20px;
      padding-bottom: 18px;
      .el-breadcrumb__inner {
        color: #92aca7;
        font-size: 18px;
      }
    }
  }
}
.el-popover {
  min-width: auto;
  padding: 0;
  .popospan {
    text-align: center;
    display: block;
    color: #a8a8a8;
    font-weight: bolder;
    font-size: 15px;
    margin: 15px 0;
  }
}
.el-message-box {
  .el-message-box__title {
    color: #d78673;
  }
  .el-message-box__close::before {
    color: #d78673;
  }
}

// 修改密码
.main {
  width: 530px;
  margin: 0 auto;
  .forget {
    font-size: 34px;
    text-align: center;
    color: #a8a8a8;
    margin-top: 40px;
  }
  .el-form {
    margin-top: 20px;
    .el-form-item__label {
      font-size: 17px;
      color: #a8a8a8;
      padding: 0 0 0;
      font-weight: 5;
    }
    .yzm {
      width: 200px;
    }
    .yzmbut {
      margin-left: 20px;
    }
  }
  .but {
    text-align: right;
    margin-top: 60px;
    padding-bottom: 20px;
    .el-button {
      height: 40px;
      width: 100px;
      background: #d78673;
      font-size: 17px;
      border: none;
      border-radius: 33px;
    }
  }
}
.el-dialog__body {
  padding: 0;
}

// 上传头像
.el-dialog {
  width: 45%;
  border-radius: 20px;
  .el-dialog__header .el-dialog__title {
    color: #d78673;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #d78673;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>